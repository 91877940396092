var generic = generic || {};

(function ($) {
  Drupal.behaviors.localeInterstitial = {
    attach: function (context, settings) {
      var cookieName = 'POPUP_INTERSTITIAL';
      var forcedLocaleHash = 'forcedlocale';
      var hasLocaleIntCookie = hasCookieCurrentLocale();

      if (!hasLocaleIntCookie) {
        displayLocaleInterstitial();
      }

      $(document).on('displayLocaleInterstitial', function () {
        displayLocaleInterstitial();
      });

      updateCountryLocaleIcon();
      $('.js-gnav-util-trigger--country_selector').on('click', function (event) {
        event.preventDefault();
        openLocaleOverlay('switch_country');
      });

      function isLocaleForced() {
        return window.location.hash.split('#').filter(function (hashes) {
          return hashes.toLowerCase() === forcedLocaleHash;
        }).length;
      }

      function updateCountryLocaleIcon() {
        var countrySelectorIcon = $('.js-gnav-util-trigger--country_selector').find(
          '.gnav-util__icon__country_selector-name'
        );
        var hasCookieInt = $.cookie('stores');
        var currentLocale = $.cookie('LOCALE') ? $.cookie('LOCALE').toUpperCase() : '';
        var $countryCode = $('.js-content-block', context).data('country-code');

        if (hasCookieInt === '1' && currentLocale === 'EN_US' && !$countryCode) {
          countrySelectorIcon.html('INT');
        } else {
          if ($countryCode) {
            countrySelectorIcon.html($countryCode.toUpperCase());
          }
        }
      }
      function hasCookieCurrentLocale() {
        var localeName = $.cookie('LOCALE') ? $.cookie('LOCALE').toUpperCase() : '';
        var storeName = $.cookie('stores') === '1' ? '_store' : '';

        storeName += isJPPage() === 1 ? '_JP' : '';
        var valueCookieForCurrentLocale = 'POPUP_INTERSTITIAL_' + localeName + storeName;
        var hasLocaleIntCookie = 0;

        if (
          $.cookie(cookieName) !== undefined &&
          $.cookie(cookieName) !== null &&
          $.cookie(cookieName) === valueCookieForCurrentLocale
        ) {
          hasLocaleIntCookie = 1;
        }

        return hasLocaleIntCookie;
      }

      function displayLocaleInterstitial() {
        generic.jsonrpc.fetch({
          method: 'locale.detect',
          params: [],
          onSuccess: function (jsonRpcResponse) {
            setOverlayLocale(jsonRpcResponse.getValue());
          },
          onFailure: function () {
            setOverlayLocale();
          }
        });
      }

      function locateSelectCountryOption() {
        var localeInsterstitialOverlay = $('.locate-interstitial-overlay');

        localeInsterstitialOverlay.on('click', 'a.country-link', function (e) {
          event.preventDefault();
          var prefLink = $(this).attr('href');

          generic.overlay.hide();
          window.location.href = prefLink;
        });
      }

      function setupCookieInterstitialNoThanks() {
        var localeName = $.cookie('LOCALE') ? $.cookie('LOCALE').toUpperCase() : '';
        var storeName = $.cookie('stores') === '1' ? '_store' : '';

        storeName += isJPPage() === 1 ? '_JP' : '';
        var cookieValue = 'POPUP_INTERSTITIAL_' + localeName + storeName;

        $.cookie(cookieName, cookieValue, {
          // expires: 14,
          path: '/'
        });
      }

      function setOverlayLocale(overlayLocale) {
        if (!overlayLocale) {
          return;
        }

        var country_from_ip = overlayLocale.country_from_ip;
        var siteLocale = overlayLocale.locale.split('_');

        country_from_ip = country_from_ip === 'CA' ? 'US' : country_from_ip;

        var storeCookieName = 'stores',
          hasCookieInt = $.cookie(storeCookieName);

        // Accessing the site from the same country's IP
        if (country_from_ip === siteLocale[1] && !isJPPage()) {
          return;
        }

        // Accessing JP page from Japan IP.
        if (country_from_ip === 'JP' && isJPPage()) {
          return;
        }

        var countrylistArray = new Array();
        var countrylistOverlay = '';
        var europeanCountryList = $('#localeOverlay').find('input.eu_countries_list').val().toUpperCase();

        $('#localeInterstitial .country-list a.country-link').each(function (e) {
          countrylistArray.push($(this).data('country').toUpperCase());
        });
        countrylistOverlay = countrylistArray.toString();

        var isEuropeanCountries = europeanCountryList.indexOf(overlayLocale.country_from_ip) !== -1 ? 1 : 0;
        var isInternationalcountries =
          hasCookieInt === '1' &&
          countrylistOverlay.indexOf(country_from_ip) === -1 &&
          europeanCountryList.indexOf(country_from_ip) === -1
            ? 1
            : 0;

        // International countries other than EU, country list in the location pppup and the cookie international is set.
        if (isInternationalcountries) {
          return;
        }

        var isFranceIp = 1;

        if (siteLocale[1] === 'UF' && isEuropeanCountries && country_from_ip !== 'FR') {
          isFranceIp = 0;
        }

        if (!isFranceIp) {
          return;
        }
      }

      function openLocaleOverlay(action = '') {
        if (isLocaleForced() && action !== 'switch_country') {
          setupCookieInterstitialNoThanks();
        } else {
          generic.overlay.launch({
            content: $('#localeOverlay').html(),
            width: 534,
            maxWidth: '100%',
            height: 'auto',
            cssClass: 'locate-interstitial-overlay',
            onComplete: function () {
              locateSelectCountryOption();
              setupCookieInterstitialNoThanks();

              var resizeFn = _.debounce(function () {
                $.colorbox.resize();
              }, 400);

              $.colorbox.resize();
              resizeFn();
              $(window).on('resize.interstitial', resizeFn);
              $('body').addClass('locate-interstitial-popup');
            }
          });
        }
      }

      function isJPPage() {
        return $('.js-about-jp-page', context).length ? 1 : 0;
      }
    }
  };
})(jQuery);
